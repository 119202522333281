import homefoot from '../../Media/homefoot.jpg'

const HomepageFooter = () => {


return(
    <>
    <center className="my-16">
    <h1 className="text-6xl md:text-8xl font-black">Live</h1>
    <h1 className="text-6xl md:text-8xl font-black">with</h1>
    <h1 className="text-6xl md:text-8xl font-black">Confidence</h1>

    <div >
    <img src={homefoot} className='rounded-lg mt-32'  style={{ width:"95vw"}} />
    </div>
    </center>
    </>
)

}


export default HomepageFooter