import HomepageAllOthers from "../../Pages/Homepage/AllProducts"
import SearchResults from "./SearcgResults"

const SearchSection = () => {



    return(
        <header className="relative">
            <header className="relative">
  <nav aria-label="Top">

    {/* Secondary navigation */}
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="border-b border-gray-200">
          <div className="flex h-16 items-center justify-between">


          <input className="p-4 w-full focus:outline-none border-r-4 focus:border-[#ffcb05] transition" placeholder="What are you looking for?"/>
<button className="p-4"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <circle cx="11" cy="11" r="8" />
  <line x1="21" y1="21" x2="16.65" y2="16.65" />
</svg></button>


            
        
       
          </div>
        </div>

<br></br>

<section>
  <h1 className="text-3xl ">Search Results</h1>
  <SearchResults/>
</section>





      </div>
    </div>
  </nav>


</header>

 <div className="p-10" style={{minHeight: '2vh'}}>
    
</div>

  
</header>

    )
}


export default SearchSection