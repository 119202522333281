const HomepageNewArrival = () => {

return(
    <>
     <center>
            <h1 className='font-black text-5xl my-8'>
        New Arrivals
            </h1>
            </center>



            <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-full lg:grid-cols-3 lg:gap-x-8 lg:px-8">
  <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
    <img
      src="https://tailwindui.com/img/ecommerce-images/product-page-02-secondary-product-shot.jpg"
      alt="Two each of gray, white, and black shirts laying flat."
      className="h-full w-full object-cover object-center"
    />
  </div>
  <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
    <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
      <img
        src="https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-01.jpg"
        alt="Model wearing plain black basic tee."
        className="h-full w-full object-cover object-center"
      />
    </div>
    <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
      <img
        src="https://tailwindui.com/img/ecommerce-images/product-page-02-tertiary-product-shot-02.jpg"
        alt="Model wearing plain gray basic tee."
        className="h-full w-full object-cover object-center"
      />
    </div>
  </div>
  <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
    <img
      src="https://tailwindui.com/img/ecommerce-images/product-page-02-featured-product-shot.jpg"
      alt="Model wearing plain white basic tee."
      className="h-full w-full object-cover object-center"
    />
  </div>
</div>
</>
)
}

export default HomepageNewArrival