import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SearchSection from './SearchSection';

export default function SearchDrawer() {
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 450 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {['top'].map((anchor) => (
        <React.Fragment key={anchor}>

<div className="md:hidden block flex-shrink flex-grow-0 justify-start px-2" onClick={toggleDrawer(anchor, true)}>
    <div className="inline-block">
      <div className="inline-flex items-center max-w-full">
        <button
          className="flex items-center flex-grow-0 flex-shrink pl-2 relative border rounded-full px-1  py-1"
          type="button"
        >
          <div className="block flex-grow flex-shrink overflow-hidden">
           
          </div>
          <div className="flex items-center justify-center relative  h-8 w-8 rounded-full">
          <svg
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              style={{
                display: "block",
                fill: "none",
                height: 12,
                width: 12,
                stroke: "currentcolor",
                strokeWidth: "5.33333",
                overflow: "visible"
              }}
            >
              <g fill="none">
                <path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9" />
              </g>
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
        
<div className="hidden sm:block flex-shrink flex-grow-0 justify-start px-2" onClick={toggleDrawer(anchor, true)}>
    <div className="inline-block">
      <div className="inline-flex items-center max-w-full">
        <button
          className="flex items-center flex-grow-0 flex-shrink pl-2 relative w-60 border rounded-full px-1  py-1"
          type="button"
        >
          <div className="block flex-grow flex-shrink overflow-hidden">
            Start your search
          </div>
          <div className="flex items-center justify-center relative  h-8 w-8 rounded-full">
            <svg
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              style={{
                display: "block",
                fill: "none",
                height: 12,
                width: 12,
                stroke: "currentcolor",
                strokeWidth: "5.33333",
                overflow: "visible"
              }}
            >
              <g fill="none">
                <path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9" />
              </g>
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
        
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
          <SearchSection/>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}