import HomepageBottomHero from "./BottomHero"
import HomepageCategories from "./Categories"
import HomepageNewArrival from "./NewArrival"
import HomepagePromo from "./Promo"
import HomepageTrending from "./TrendingProducts"
import HeroVideo from '../../Media/homehero.mp4'
import { useEffect } from "react"
import TopSelling from "./TopSelling"

const HomepageProducts = () => {
    useEffect(() => {
        const video = document.getElementById('Home2Video');
    
        video.muted = true;
        video.playsInline = true;
        video.play();
  
      }, []);
    return(
        <section className="pb-10 ">
        


        <HomepageNewArrival/>
<HomepagePromo/>
        
        <HomepageTrending/>
        <br></br>
        <center>
          <h1 className="text-5xl font-black">
            Fashion for All
          </h1>
        </center>
<HomepageCategories/>
<TopSelling/>
<section className="HomnepageVideo" >
            <div className="max-h-1/2">
                <video   src={HeroVideo} autoPlay={true} muted  loop playsinline id='Home2Video'></video>
            </div>
        </section>
        
        <HomepageBottomHero/>


</section>

    )
}


export default HomepageProducts