// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import homeCarousel1 from '../../Media/homeCarousel1.jpg'
import homeCarousel2 from '../../Media/homeCarousel2.jpg'
import homeCarousel3 from '../../Media/homeCarousel3.jpg'
import homeCarousel4 from '../../Media/homeCarousel4.jpg'


const HomepageCarousel = () => {


    return(
        <>    <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>    <img src={homeCarousel1} style={{width:"100vw", height:"60vh"}} /></SwiperSlide>
        <SwiperSlide>    <img src={homeCarousel2} style={{width:"100vw", height:"60vh"}} /></SwiperSlide>
        <SwiperSlide>    <img src={homeCarousel3} style={{width:"100vw", height:"60vh"}} /></SwiperSlide>
        <SwiperSlide>    <img src={homeCarousel4} style={{width:"100vw", height:"60vh"}} /></SwiperSlide>
 
      </Swiper>
      <h1 className='bg-amber-900	text-white font-black text-xl p-2'>
            THE PERFECT LOOK FOR THE SEASON, IS ALWAYS A STEP AHEAD
            </h1>
      </>
    )
}

export default HomepageCarousel