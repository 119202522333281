import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';

export const header = ReactDOM.createRoot(document.getElementById('header'));
export const root = ReactDOM.createRoot(document.getElementById('root'));
export const footer = ReactDOM.createRoot(document.getElementById('footer'));
header.render(
  <React.StrictMode>
    <Navbar />
  </React.StrictMode>
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
footer.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>
);

