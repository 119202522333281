import { Container } from "@mui/material"
import NavbarDrawer from "./SideDrawer"
import SearchDrawer from "./TopDrawer"
import FloatingButton from "../FAB/FloatingActionButton"
import { root } from "../.."
import CartPage from "../../Pages/Cart/CartPage"

const Navbar = () => {


    return(
       <nav className="border bg-white w-full flex relative justify-between items-center mx-auto px-8 h-20">
      
  {/* logo */}
  <div className="inline-flex">
    <a className="_o6689fn" href="/">
      <div className="hidden md:block text-2xl font-bold">
     Intrederium
      </div>
      <div className="block md:hidden">
      <div className="text-xl font-bold">
     Intrederium
      </div>
      </div>
    </a>
  </div>
  {/* end logo */}
  {/* search bar */}
  <SearchDrawer/>
  {/* end search bar */}
  {/* login */}
  <div className="flex-initial">
    <div className="flex justify-end items-center relative">
      <div className="flex hidden md:block mr-4 items-center">
      <a
          className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full"
          href="#"
        >
          <div className="flex items-center relative cursor-pointer whitespace-nowrap">
         Home
          </div>
        </a>

        <a
          className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full"
          href="#"
        >
          <div className="flex items-center relative cursor-pointer whitespace-nowrap">
         Men
          </div>
        </a>

        <a
          className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full"
          href="#"
        >
          <div className="flex items-center relative cursor-pointer whitespace-nowrap">
         Women
          </div>
        </a>
      
        <a
          className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full"
          href="#"
        >
          <div className="flex items-center relative cursor-pointer whitespace-nowrap">
         Kids
          </div>
        </a>
        
        <a
          className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full"
          href="#"
        >
          <div className="flex items-center relative cursor-pointer whitespace-nowrap">
         Sale
          </div>
        </a>
       
        <a
          className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full"
          href="#"
        >
          <div className="flex items-center relative cursor-pointer whitespace-nowrap">
         Track your order
          </div>
        </a>
      
        <a
          className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full"
          href="#"
          onClick={()=>{
            root.render(<CartPage/>)
          }}
        >
          <div className="flex items-center relative cursor-pointer whitespace-nowrap">
         Cart
          </div>
        </a>
        <a
          className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full"
          href="#"
        >
          <div className="flex items-center relative cursor-pointer whitespace-nowrap">
         Support
          </div>
        </a>
      </div>
   <NavbarDrawer/>
    </div>
  </div>
  {/* end login */}
</nav>

    )
}

export default Navbar