
import "./homepage.css"

const HomepageTitle = () => {

    return(
        <div className='bg-black'>
        <section className="bvfwrapper">
  <div className="bvftop">Intrederium</div>
  <div className="bvfbottom" aria-hidden="true">Intrederium</div>
</section>
        </div>
    )
}

export default HomepageTitle