import { useEffect } from 'react'
import HeroVideo from '../../Media/homehero.mp4'
import HomepageCarousel from './Carousel'
import HomepageProducts from './Products'
import HomepageTitle from './Title'
import HomepageAllOthers from './AllProducts'
import { Divider } from '@mui/material'
import HomepageFooter from './HomeFooter'
const Homepage = () => {
    useEffect(() => {
        const video = document.getElementById('HomeVideo');
    
        video.muted = true;
        video.playsInline = true;
        video.play();
  
      }, []);

    return(

       <main>

         <section className="topSection" >
            
        <center>
        <HomepageCarousel/>
           
        </center>
      <HomepageTitle/>
        </section>

         <section className="HomnepageVideo" >
            <div className="max-h-1/2">
                <video   src={HeroVideo} autoPlay={true} muted  loop playsinline id='HomeVideo'></video>
            </div>
        </section>


        <section>
           
        <HomepageProducts/>
        </section>

        <HomepageAllOthers/>

<br></br>
        <Divider></Divider>
<br></br>
        <HomepageFooter/>
       </main>
    )
}

export default Homepage